import React from 'react'

const Blog = () => {
  return (
    <>
     <div
      // eslint-disable-next-line react/style-prop-object
      src=""
    
      
      className="blog-single-banner wf-section"
    >
      <div className="container w-container">
        <div className="blog-banner-content-area">
          <div className="blog-single-date-block">
            <img
              src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63ce172b8a1d4b4d5f2d9bd0_calendar-white-icon.svg"
              loading="lazy"
              alt="Calendar Icon"
            />
            <div>
              <p className="blog-date blog-date-white-color">14</p>
              <p className="blog-month">July</p>
            </div>
          </div>
          <h1 className="page-title">
            Five things you should know when installing a CCTV camera
          </h1>
        </div>
      </div>
      <div className="blog-banner-overlay"></div>
    </div>
    <div className="blog-single wf-section">
      <div className="container w-container">
        <div className="w-layout-grid blog-single-grid">
          <div id="w-node-b86d2815-4769-57df-64a7-92e6b5961e44-950c867d">
            <div
              data-w-id="f1f844ee-82c7-aa56-f28d-e022a088bedd"
              //style="opacity: 0"
              className="blog-single-content-wrap"
            >
              <div className="w-richtext">
                <h3>Your Security Solutions</h3>
                <p>
                When installing a CCTV camera system, there are several important factors to consider to ensure optimal performance and effectiveness. Here are five key things you should know:
                </p>
                <blockquote>
                  "Security is not just about locks, cameras, and alarms. It's a state of mind that comes from knowing you've taken the necessary steps to protect what matters most.”
                </blockquote>
                <h3>Safety is a collective effort</h3>
                <p>
                  <ul>
                    <li>Purpose and Coverage Area</li>
                    <li>Camera Selection</li>
                    <li>Camera Placement</li>
                    <li>Lighting Condition</li>
                    <li>Data Storage and Security</li>
                  </ul>

                </p>
                <figure
                  className="w-richtext-figure-type-image w-richtext-align-fullwidth"
                  //style={{width: "870px"}}
                  
                >
                  <div>
                    <img
                      src="https://www.independent.co.ug/wp-content/uploads/2019/10/cctv-camera.jpg"
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <figcaption>closed circuit camera</figcaption>
                </figure>
                <p>
                Additionally, it's important to consult with professionals or experienced CCTV installers to ensure compliance with local laws and regulations regarding video surveillance. They can provide valuable insights and expertise to help you design and implement an effective CCTV camera system tailored to your specific needs.
                </p>
                <p>
                  We can do this for you
                </p>
              </div>
            </div>
            <div className="blog-single-social-wrap">
              {/* <div className="blog-social-wrap">
                <a
                  href="http://www.instagram.com"
                  className="blog-social-link w-inline-block"
                  ><img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63cfc7967257e8661f67168f_instagram-icon.svg"
                    loading="lazy"
                    alt="Instagram Icon"
                    className="social-icon" /></a
                ><a
                  href="http://www.facebook.com"
                  className="blog-social-link w-inline-block"
                  ><img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63cfc76edb063abc2787ee1d_facebook-icon.svg"
                    loading="lazy"
                    alt="Facebook Icon"
                    className="social-icon" /></a
                ><a
                  href="http://www.twitter.com"
                  className="blog-social-link w-inline-block"
                  ><img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63cfc6e1b69b996cd5a8e78a_twitter-icon.svg"
                    loading="lazy"
                    alt="Twitter Icon"
                    className="social-icon" /></a
                ><a
                  href="http://www.linkedin.com"
                  className="blog-social-link w-inline-block"
                  ><img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63cfc7b9a2891b8728812273_linedin-icon.svg"
                    loading="lazy"
                    alt="LinkedIn Icon"
                    className="social-icon"
                /></a>
              </div> */}
              <div className="blog-tag-wrap">
                <p className="blog-tag-text">Category:</p>
                <a href="/security" className="blog-category-link"
                  >Security</a
                >
              </div>
            </div>
          </div>
          <div
            id="w-node-_0ad85f99-248e-4357-0601-d3b02a52490c-950c867d"
            data-w-id="0ad85f99-248e-4357-0601-d3b02a52490c"
            //style="opacity: 0"
          >
            <div className="blog-category-block">
              <h2 className="blog-sidebar-title">Categories</h2>
              <div className="mg-top-20">
                <div className="w-dyn-list">
                  <div role="list" className="w-dyn-items">
                    <div role="listitem" className="w-dyn-item">
                      <div className="blog-category-wrap">
                        <a
                          href="/security"
                          className="blog-category"
                          >Security</a
                        >
                      </div>
                    </div>
                    {/* <div role="listitem" className="w-dyn-item">
                      <div className="blog-category-wrap">
                        <a
                          href="/blog-categories/transport-industries"
                          className="blog-category"
                          >Transport Industries</a
                        >
                      </div>
                    </div>
                    <div role="listitem" className="w-dyn-item">
                      <div className="blog-category-wrap">
                        <a
                          href="/blog-categories/transport"
                          className="blog-category"
                          >Transport</a
                        >
                      </div>
                    </div>
                    <div role="listitem" className="w-dyn-item">
                      <div className="blog-category-wrap">
                        <a
                          href="/blog-categories/warehouse"
                          className="blog-category"
                          >Warehouse</a
                        >
                      </div>
                    </div> */}
                    {/* <div role="listitem" className="w-dyn-item">
                      <div className="blog-category-wrap">
                        <a
                          href="/blog-categories/services"
                          className="blog-category"
                          >Services</a
                        >
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-help-wrap">
              <h3 className="blog-sidebar-title">How can we help you?</h3>
              <div className="mg-top-10">
                <p>
                  We appreciate your interest please complete the form below and
                  we will contact you to discuss your warehousing, distribution,
                  air, ocean freight or any other logistics needs.
                </p>
              </div>
              <div className="mg-top-30">
                <a href="/contact-us" className="primary-button w-button"
                  >Contact</a
                >
              </div>
            </div>
            <div className="blog-contact-wrap">
              <div className="blog-subtitle-wrap">
                <div className="subtitle-border"></div>
                <h3 className="blog-sidebar-title text-white">Get In Touch</h3>
              </div>
              <div className="blog-contact-block">
                <p className="blog-link-text">Need help?</p>
                <a href="tel:(00)1123456789" className="blog-link-text hover-yellow"
                  >(+234)803 358 6496</a
                >
              </div>
              <div className="blog-contact-line"></div>
              <div className="blog-contact-block">
                <p className="blog-link-text">Email</p>
                <a href="/contact" className="blog-link-text hover-yellow"
                  >haybravointernational@gmail.com</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Blog