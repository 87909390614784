/* eslint-disable import/no-anonymous-default-export */
import logo from "../assets/images/Screenshot_2023-07-04_at_09.44.41-removebg-preview (1).png"
import highrise from "../assets/images/Abu-Dhabi_(Public__Domain)neildodhia210122-050144.jpeg"
import securitylogo from "../assets/images/icons8-bullet-camera-48.png"
import farmlogo from '../assets/images/icons8-palm-tree-48.png';
import hotellogo from "../assets/images/icons8-5-star-hotel-48.png";
import minilogo from "../assets/images/icons8-oil-pump-48.png";
import securityexpert from '../assets/images/X G.webp';
import privatesec from '../assets/images/186541780_m.jpeg';
import cctv from '../assets/images/CCTV.webp';
import haruna from '../assets/images/haruna.jpeg';
import hotelabout from '../assets/images/business-suite.webp';
import privatesecurity from '../assets/images/privatesecurity.jpg';
import mine from '../assets/images/mine.jpeg';
import hotel1 from '../assets/images/hotelkssxx.webp';
import hotel2 from '../assets/images/hotezzz.jpeg';
import cctvv from '../assets/images/cctvvv.jpeg';
import securitymen from '../assets/images/private-security-1280x720.webp'
import farm from '../assets/images/farm.jpeg'
import iconbed from '../assets/images/icons8-bed-64.png';
import icondining from '../assets/images/icons8-dining-room-16.png';
import iconhospital from '../assets/images/icons8-hospitality-64.png';
import iconaccomodation from '../assets/images/icons8-accomodation-64.png';
import peter_olulosha from '../assets/images/WhatsApp Image 2023-07-10 at 4.34.00 PM.jpeg';
import umar from '../assets/images/WhatsApp Image 2023-07-10 at 4.34.52 PM.jpeg';
import ceo from '../assets/images/WhatsApp Image 2023-07-10 at 4.33.03 PM copy.jpeg';
import abiodun from '../assets/images/WhatsApp Image 2023-07-10 at 4.43.25 PM.jpeg';
import nafiu from '../assets/images/WhatsApp Image 2023-07-10 at 4.48.58 PM.jpeg';
import felix from '../assets/images/WhatsApp Image 2023-07-10 at 4.54.57 PM.jpeg';
import labaran from '../assets/images/WhatsApp Image 2023-07-10 at 4.35.55 PM.jpeg';
import gabriel from '../assets/images/WhatsApp Image 2023-07-10 at 4.36.33 PM.jpeg'
import abubaker from '../assets/images/WhatsApp Image 2023-07-10 at 4.37.20 PM.jpeg'
import yakubu from '../assets/images/WhatsApp Image 2023-07-10 at 4.38.14 PM.jpeg'
import james from '../assets/images/WhatsApp Image 2023-07-10 at 4.41.35 PM.jpeg';
import umaru from '../assets/images/WhatsApp Image 2023-07-10 at 4.42.29 PM.jpeg';
import oseni from '../assets/images/WhatsApp Image 2023-07-10 at 4.44.55 PM.jpeg'
import fatai from '../assets/images/WhatsApp Image 2023-07-10 at 4.45.56 PM.jpeg'
import aliu from '../assets/images/WhatsApp Image 2023-07-10 at 4.46.52 PM.jpeg'
import joshua from '../assets/images/WhatsApp Image 2023-07-10 at 4.47.57 PM.jpeg'
import imelo from '../assets/images/WhatsApp Image 2023-07-10 at 4.49.45 PM.jpeg'
import obinna from '../assets/images/WhatsApp Image 2023-07-10 at 4.50.59 PM.jpeg'
import micheal from '../assets/images/WhatsApp Image 2023-07-10 at 4.53.06 PM.jpeg'
import yusuf from '../assets/images/WhatsApp Image 2023-07-10 at 4.53.54 PM.jpeg'
import bashir from '../assets/images/WhatsApp Image 2023-07-10 at 4.55.52 PM.jpeg'
import idris from '../assets/images/WhatsApp Image 2023-07-10 at 4.56.57 PM.jpeg'
import nanpan from '../assets/images/WhatsApp Image 2023-07-10 at 4.57.50 PM.jpeg'
import muluku from '../assets/images/WhatsApp Image 2023-07-10 at 4.59.34 PM.jpeg'
import moses from '../assets/images/WhatsApp Image 2023-07-10 at 5.00.43 PM (1).jpeg'
import plantteam1 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.47 PM.jpeg'
import plantteam2 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.46 PM.jpeg'
import haybravo1 from '../assets/images/hay-bravo-hotels-996215-13.jpeg'
import haybravo2 from '../assets/images/hay-bravo-hotels-996215-23.jpeg'
import haybravo3 from '../assets/images/hay-bravo-hotels-996215-7.jpeg'
import haybravo4 from '../assets/images/hay-bravo-hotels-996215-47.jpeg'
import haybravo5 from '../assets/images/hay-bravo-hotels-996215-60.jpeg'
import haybravo6 from '../assets/images/hay-bravo-hotels-996215-59.jpeg'
import farm1 from '../assets/images/vbnm,.:;".jpeg'
import farm2 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.47 PM (1).jpeg'
import farm3 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.47 PM.jpeg'
import farm4 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.48 PM.jpeg'
import farm5 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.49 PM.jpeg'
import farm6 from '../assets/images/WhatsApp Image 2023-07-10 at 4.26.50 PM (1).jpeg'
import escortservice from '../assets/images/icons8-security-guard-skin-type-3-48.png'
import securityconsult from '../assets/images/icons8-security-64.png'
import securotyarc from '../assets/images/icons8-cyber-security-100.png'
import certificate from '../assets/images/icons8-training-100.png'
import securitypic1 from '../assets/images/WhatsApp Image 2023-07-11 at 7.01.41 PM.jpeg';
import securitypic2 from '../assets/images/WhatsApp Image 2023-07-11 at 7.01.41 PM (1).jpeg'
import newlogo from '../assets/images/WhatsApp Image 2023-07-25 at 3.18.54 PM.jpeg'









export default{
    logo,
    newlogo,
    highrise,
    securitylogo,
    farmlogo,
    hotellogo,
    minilogo,
    securityexpert,
    privatesec,
    cctv,
    haruna,
    hotelabout,
    privatesecurity,
    mine,
    hotel1,
    hotel2,
    cctvv,
    securitymen,
    farm,
    iconaccomodation,
    iconbed,
    icondining,
    iconhospital,
    peter_olulosha,
    umar,
    ceo,
    abiodun,
    nafiu,
    felix,
    labaran,
    gabriel,
    abubaker,
    yakubu,
    james,
    umaru,
    oseni,
    fatai,
    aliu,
    joshua,
    imelo,
    obinna,
    micheal,
    yusuf,
    bashir,
    idris,
    nanpan,
    muluku,
    moses,
    plantteam1,
    plantteam2,
    haybravo1,
    haybravo2,
    haybravo3,
    haybravo4,
    haybravo5,
    haybravo6,
    farm1,
    farm2,
    farm3,
    farm4,
    farm5,
    farm6,
    escortservice,
    securityconsult,
    securotyarc,
    certificate,
    securitypic1,
    securitypic2 
}